<template>
  <div class="graduation-test">
    <div v-loading="loading"></div>
    <div class="xy-content" v-if="!loading">
      <breadcrumb :refer="refer"></breadcrumb>
      <div class="graduation-banner">
        <img v-if="clientWidth > 768" :src="InitPage.banner_PC" alt="" width="100%">
        <img v-else :src="InitPage.banner_M" alt="" width="100%">
      </div>
      <div v-if="InitPage.abstract" class="graduation-abstract" v-html="InitPage.abstract"></div>
      <div class="graduation-test-list">
        <div class="graduation-test-item" v-for="(item, index) in resData.judges" :key="item.id">
          <div class="graduation-test-item-title">{{ index + 1 }}. {{ item.title }}</div>
          <div class="graduation-test-item-body">
            <van-radio-group v-model="params.reqJudge[index].answer">
              <van-radio :name="true" checked-color="#6b18a8">
                是
                <template #icon="props">
                  <div class="vant-radio-icon flex0" :class="props.checked ? 'activeIcon' : 'inactiveIcon'">
                    <div class="vant-radio-icon-checked"></div>
                  </div>
                </template>
              </van-radio>
              <van-radio :name="false" checked-color="#6b18a8">
                否
                <template #icon="props">
                  <div class="vant-radio-icon flex0" :class="props.checked ? 'activeIcon' : 'inactiveIcon'">
                    <div class="vant-radio-icon-checked"></div>
                  </div>
                </template>
              </van-radio>
            </van-radio-group>
          </div>
          <div class="graduation-test-item-line"></div>
        </div>

        <div class="graduation-test-item" v-for="(item, index) in resData.chooses" :key="item.id">
          <div class="graduation-test-item-title">{{ resData.judges.length + index + 1 }}. {{ item.title }}</div>
          <div v-if="item.type === 1" class="graduation-test-item-body">
            <van-radio-group v-model="params.reqChoose[index].answers">
              <van-radio
                v-for="optionItem in item.chooseItems"
                :key="optionItem.id"
                :name="optionItem.id"
                checked-color="#6b18a8"
              >
                {{ optionItem.options }}
                <template #icon="props">
                  <div class="vant-radio-icon flex0" :class="props.checked ? 'activeIcon' : 'inactiveIcon'">
                    <div class="vant-radio-icon-checked"></div>
                  </div>
                </template>
              </van-radio>
            </van-radio-group>
          </div>
          <div v-else-if="item.type === 2" class="graduation-test-item-body">
            <van-checkbox-group v-model="params.reqChoose[index].answers">
              <van-checkbox
                v-for="optionItem in item.chooseItems"
                :key="optionItem.id"
                :name="optionItem.id"
                :icon-size="clientWidth > 768 ? '18px' : '14px'"
                shape="square"
                checked-color="#6b18a8"
              >
                {{ optionItem.options }}
              </van-checkbox>
            </van-checkbox-group>
          </div>

          <div v-if="index !== resData.chooses.length - 1" class="graduation-test-item-line"></div>
        </div>

        <div class="graduation-test-operation tc">
          <el-button type="info" round :loading="btnLoad" style="width: 200px" @click="submitForm">提 交</el-button>
        </div>
      </div>
    </div>
    <shade-alert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import breadcrumb from '@/components/Breadcrumb/index.vue'
import shadeAlert from "@/components/shadeAlert/index.vue"

import { GetShareData, GetWxShares } from '@/utils/common'
import { Examination, SubmitExamination, ExamInfo } from '@/api/E3D'

export default {
  name: 'GraduationTest',
  data () {
    return {
      // !banner配置、 前言介绍
      InitPage: {
        abstract: '',
        banner_M: '',
        banner_PC: ''
      },
      // !面包屑
      refer: [
        { name: '首页', href: '/' },
        { name: 'E3D视频', href: '/Home/E3DVideo' },
        { name: '学习测试', href: ''}
      ],
      // !网站加载loading
      loading: true,
      // !提交表单loading
      btnLoad: false,
      resData: {
        // $ 单选题
        judges: [],
        // $ 多选题
        chooses: []
      },
      params: {
        // $ 单选题
        reqJudge: [],
        // $ 多选题
        reqChoose: []
      },
      // !弹窗配置
      shadeConfig: {},
    }
  },
  mounted () {
    this.GetInit()
    this.GetInitPage()
    GetShareData(1, 'GraduationTest', '')
    const share = {
      title: '邀你来战！ AVEVA E3D 剑法秘籍',
      // desc: '一站式进阶“习剑”，享多重惊喜礼遇'
    }
    GetWxShares(share);
  },
  methods: {
    // !初始化表单头部信息
    GetInitPage () {
      ExamInfo().then(res => {
        if (res.status === 1) {
          this.InitPage = res.data;
          this.InitPage.abstract = this.InitPage.abstract.replace(/\n/g, '<br/>')
        } else {
          this.$toast(res.message)
        }
      })
    },
    // !初始化表单
    GetInit () {
      Examination().then(res => {
        if (res.status === 1) {
          this.loading = false
          this.resData = res.data

          this.params.reqJudge = res.data.judges.map(item => {
            return  {
              id: item.id,
              answer: null,
              type: item.type
            }
          })

          this.params.reqChoose = res.data.chooses.map(item => {
            return  {
              id: item.id,
              answers: item.type === 1 ? '' : [],
              type: item.type
            }
          })

        } else {
          this.$toast(res.message)
        }
      })
    },
    // !必填验证、重组提交数据
    submitForm () {
      if(!this.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来考试吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: '已有剑盟账号，前往登录',
              link: '/Login?redirectUrl=' + this.$route.fullPath,
            },
            {
              text: '没有账号，立即注册',
              link: '/Register?redirectUrl=' + this.$route.fullPath,
            },
          ],
        };
        return false;
      }

      const reqJudgeIndex = this.params.reqJudge.findIndex(item => item.answer === null)
      if(reqJudgeIndex > -1) {
        this.$toast('您还有题目（第 '+ (reqJudgeIndex + 1) +' 题）未作答！')
        return false
      }

      const reqChooseIndex = this.params.reqChoose.findIndex(item => !item.answers.length)
      if(reqChooseIndex > -1) {
        this.$toast('您还有题目（第 '+ (this.resData.judges.length + reqChooseIndex + 1) +' 题）未作答！')
        return false
      }
      const checkList = this.params.reqChoose.findIndex(item => item.answers.length < 2 && item.type === 2)
      if(checkList > -1) {
        this.$toast('第 '+ (this.resData.judges.length + checkList + 1) +' 题为多选题，至少选择2项！')
        return false
      }

      this.setSubmitExamination()
    },
    // !提交接口
    setSubmitExamination () {
      this.btnLoad = true
      const params = JSON.parse(JSON.stringify(this.params))
      params.reqChoose.map(item =>
        !Array.isArray(item.answers) && item.answers ? item.answers = [item.answers] : item.answers
      )
      SubmitExamination(params).then(res => {
        if(res.status === 1) {
          this.$store.dispatch('SubmitExam', res.data.status )
          this.showAlert(res.data)
        } else {
          this.$toast(res.message)
          this.btnLoad = false
        }
      }).catch(error => {
        this.$toast(res.message)
        this.btnLoad = false
      })
    },
    // !弹窗展示
    showAlert (res) {
      if(res.status) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "学习测试通过，<br/>恭喜您获得专属勋章！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: '查看结果',
              link: '/Home/GraduationTestDetails',
            },
            {
              text: '查看勋章',
              link: '/Home/Profile/badge',
            },
            // {
            //   text: '前往查看',
            //   link: '/Home/Profile/badge',
            // },
          ],
        };
      } else {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: '很遗憾，您没有通过测试！',
          text: `您答对了${res.trueNum}道题，需要答对${res.passNum}道题通过考试`,
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: '查看结果',
              link: '/Home/GraduationTestDetails',
            },
            {
              text: '返回学习',
              link: '/Home/E3DVideo',
            }
          ],
        };
      }
    },
    // !关闭弹窗
    closeAlertChange () {
      this.$router.push('/Home/E3DVideo')
    }
  },
  components: {
    breadcrumb,
    shadeAlert
  },
  computed: {
    ...mapState(['clientWidth', 'token'])
  }
}
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>
import request from '@/utils/request'

// !E3D 视频类型筛选
export function BasicData(params) {
  return request({
    url: '/E3D/BasicData',
    method: 'get',
    params
  })
}

// !E3D 视频类型详情
export function E3DInfo(params) {
  return request({
    url: '/E3D/E3DInfo',
    method: 'get',
    params
  })
}

// !E3D 获取课程详情
export function E3DCourse(params) {
  return request({
    url: '/E3D/E3DCourse',
    method: 'get',
    params
  })
}

// !E3D 记录学习时长
export function Learning(data) {
  return request({
    url: '/E3D/Learning',
    method: 'post',
    data
  })
}

// !学习测试试题
export function Examination(params) {
  return request({
    url: '/E3D/Examination',
    method: 'GET',
    params
  })
}
// !提交学习测试试题
export function SubmitExamination(data) {
  return request({
    url: '/E3D/SubmitExamination',
    method: 'POST',
    data
  })
}
// !学习测试基本信息
export function ExamInfo(params) {
  return request({
    url: '/E3D/ExamInfo',
    method: 'GET',
    params
  })
}

// !学习测试试题详情
export function ExaminationDetails(params) {
  return request({
    url: '/E3D/ExaminationLog',
    method: 'GET',
    params
  })
}